import logo from './assets/logo.png';
import courses from './assets/courses.svg';
import money from './assets/money.svg';
import umniy from './assets/umniy.svg';

//todo передавать через пропсы доп пункты?

const getMenuItems2 = appConfig => {
    const LMS_BASE_URL = 'https://smartinc.uk'
  return [
    {
      svg: true,
      link: ''.concat(LMS_BASE_URL, '/dashboard'),
      img: (<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 11.6884C1 10.4642 1.56058 9.30744 2.52142 8.54888L10.8714 1.95677C12.3247 0.80948 14.3753 0.809479 15.8286 1.95677L24.1786 8.54888C25.1394 9.30744 25.7 10.4642 25.7 11.6884V22C25.7 24.2091 23.9091 26 21.7 26H18.25C17.6977 26 17.25 25.5523 17.25 25V20.2C17.25 19.0954 16.3546 18.2 15.25 18.2H11.45C10.3454 18.2 9.45 19.0954 9.45 20.2V25C9.45 25.5523 9.00229 26 8.45 26H5C2.79086 26 1 24.2091 1 22L1 11.6884Z"
                stroke="white"/>
          </svg>
      ),
      title: 'Home'
    },
    {
      svg: true,
      link: '#',
      img: (<svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.5355 17.3422C22.7311 17.9441 22.7694 19.6477 21.6022 20.3035L14.5029 24.2921C12.8229 25.236 10.7771 25.236 9.09705 24.2921L2.10136 20.3617C0.914074 19.6946 0.979495 17.9533 2.21343 17.3785M14.5029 12.7734L21.7469 8.70353C22.8844 8.06446 22.8844 6.41686 21.7469 5.77779L14.503 1.70792C12.8229 0.764029 10.7771 0.764027 9.09705 1.70791L1.85311 5.77779C0.715632 6.41686 0.715628 8.06446 1.85311 8.70353L9.09705 12.7734C10.7771 13.7173 12.8229 13.7173 14.5029 12.7734Z"
                stroke="white" strokeLinecap="round"/>
            <path
                d="M21.7469 11.7119C22.8844 12.351 22.8844 13.9986 21.7469 14.6377L14.5029 18.7075C12.8229 19.6514 10.7771 19.6514 9.09705 18.7075L1.85311 14.6377C0.715628 13.9986 0.715631 12.351 1.85311 11.7119"
                stroke="#466A68" strokeLinecap="round"/>
          </svg>

      ),
      title: 'Education',
      active: true,
      submenu: [
        {
          title: 'Trainings',
          link: '#',
        },
        {
          title: 'Schedule',
          link: '#',
        },
        {
          title: 'Answers',
          link: '#',
        },
        {
          title: 'Working in threes',
          link: '#',
        },
        {
          title: 'Testing',
          link: '#',
        },
        {
          title: 'Questionnaires',
          link: '#',
        },
        {
          title: 'Diplomas',
          link: '#',
        },
        {
          title: 'Quality control',
          link: '#',
        },
      ]
    },
    {
      svg: true,
      link: ''.concat(LMS_BASE_URL, '/courses'),
      img: (<svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.93715 10.4713C2.20212 8.48412 3.89725 7 5.90207 7H15.6979C17.7027 7 19.3979 8.48412 19.6628 10.4713L20.9962 20.4713C21.3159 22.8693 19.4504 25 17.0313 25H4.56874C2.14958 25 0.284096 22.8693 0.603821 20.4713L1.93715 10.4713Z"
                stroke="white"/>
            <path d="M7.79922 16.6L10.1992 19L14.9992 14.2" stroke="#466A68" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M15.6 9.4V5C15.6 2.79086 13.8091 1 11.6 1H10C7.79086 1 6 2.79086 6 5L6 9.4" stroke="#466A68"
                  strokeLinecap="round"/>
          </svg>
      ),
      title: 'Shop'
    },
    {
      svg: true,
      link: ''.concat(LMS_BASE_URL, '/orders'),
      img: (<svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12.5351 24.071H3.88394C2.85728 24.071 1.99129 23.3065 1.86395 22.2878L1.01573 15.5021C0.943307 14.9227 1.12317 14.3401 1.5096 13.9023C1.89603 13.4646 2.45182 13.2139 3.03573 13.2139H11.6869C12.7136 13.2139 13.5796 13.9784 13.7069 14.9972L14.5551 21.7829C14.6275 22.3623 14.4477 22.9448 14.0612 23.3826C13.6748 23.8203 13.119 24.071 12.5351 24.071Z"
                  stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.9578 24.0711H12.2656" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <circle cx="16.6071" cy="4.73214" r="3.73214" stroke="#466A68" strokeLinecap="round"
                    strokeLinejoin="round"/>
            <path
                d="M17.2852 13.2148H21.3566C23.6052 13.2148 25.428 15.0377 25.428 17.2863V18.6434C25.428 19.3929 24.8204 20.0006 24.0709 20.0006H19.9994"
                stroke="#466A68" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
      ),
      title: 'Students',
      submenu: [
        {
          title: 'Users',
          link: '#',
        },
        {
          title: 'Groups',
          link: '#',
        }]
    },
    {
      svg: true,
      link: '#',
      img: (<svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.3" d="M11.5977 23.0006L13.2269 21.3714L11.5977 19.7422" stroke="#49C2BB"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M19.3316 15.6665V14.872C19.3316 12.9482 17.7721 11.3887 15.8483 11.3887H10.5927C8.66892 11.3887 7.10938 12.9482 7.10938 14.872V15.6665"
                stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <circle cx="13.2253" cy="4.66667" r="3.66667" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.24453 14.4443C1.52746 14.9323 1.06989 15.7187 1 16.5832C1 19.2698 6.41899 21.4485 13.1228 21.47"
                  stroke="#466A68" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M24.2017 14.4453C24.9187 14.9333 25.3763 15.7197 25.4462 16.5842C25.4462 18.7732 21.849 20.6255 16.8906 21.2487"
                stroke="#466A68" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
      ),
      title: 'Affiliate program'
    },
    {
      svg: true,
      link: '#',
      img: (<svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.8984 19.6309C14.8984 20.6071 16.8775 21.399 19.3189 21.399C21.7602 21.399 23.7393 20.6071 23.7393 19.6309"
                stroke="#466A68" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M14.8945 16.0312V23.2317C14.8958 24.208 16.8736 24.9999 19.3149 24.9999C21.7563 24.9999 23.7341 24.208 23.7354 23.2317V16.0312"
                stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.79297 14.8952H6.05612" stroke="#466A68" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.84375 14.8952H11.1069" stroke="#466A68" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.79297 18.6843H6.05612" stroke="#466A68" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.84375 18.6843H11.1069" stroke="#466A68" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.9473 7.31517H1" stroke="#466A68" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M11.1052 22.4737H3.52631C2.13107 22.4737 1 21.3426 1 19.9473V3.52631C1 2.13107 2.13107 1 3.52631 1H17.421C18.8163 1 19.9473 2.13107 19.9473 3.52631V11.1052"
                stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.79297 11.1042H6.05612" stroke="#466A68" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.84375 11.1042H11.1069" stroke="#466A68" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.8945 11.1042H16.1577" stroke="#466A68" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M14.8958 16.0318C14.8958 17.0081 16.8749 17.8 19.3162 17.8C21.7575 17.8 23.7354 17.0081 23.7354 16.0318C23.7354 15.0543 21.755 14.2637 19.3149 14.2637C16.8749 14.2637 14.8958 15.0556 14.8945 16.0318"
                stroke="#466A68" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
      ),
      title: 'Sales',
      submenu: [
        {
          title: 'Order list',
          link: '#',
        },
        {
          title: 'Products',
          link: '#',
        },
        {
          title: 'Payments',
          link: '#',
        },
        {
          title: 'My purchases',
          link: '#',
        },
        {
          title: 'Promotions',
          link: '#',
        }]
    },
    {
      svg: true,
      link: '#',
      img: (<svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M1 22.3886V21.8923C1.00403 20.4805 2.14752 19.337 3.55933 19.333H8.21844C9.63025 19.337 10.7737 20.4805 10.7778 21.8923V22.3886C10.7778 22.7261 10.5042 22.9997 10.1667 22.9997H1.61111C1.2736 22.9997 1 22.7261 1 22.3886Z"
                  stroke="#466A68" strokeLinecap="round" strokeLinejoin="round"/>
            <circle cx="5.88976" cy="13.8331" r="2.44444" stroke="#466A68" strokeLinecap="round"
                    strokeLinejoin="round"/>
            <path
                d="M3.44531 7.11111V4.66667C3.44531 2.64162 5.08694 1 7.11198 1H21.7786C23.8037 1 25.4453 2.64162 25.4453 4.66667V15.6667C25.4453 17.6917 23.8037 19.3333 21.7786 19.3333H13.2231"
                stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12.3047 12.6105V7.72342C12.3047 7.50394 12.4224 7.30131 12.613 7.19257C12.8037 7.08384 13.038 7.08569 13.2269 7.19744L17.3585 9.64095C17.5444 9.75093 17.6585 9.95091 17.6585 10.1669C17.6585 10.383 17.5444 10.583 17.3585 10.6929L13.2269 13.1365C13.038 13.2482 12.8037 13.2501 12.613 13.1413C12.4224 13.0326 12.3047 12.83 12.3047 12.6105Z"
                  stroke="#466A68" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
      ),
      title: 'Webinars',
      submenu: [
        {
          title: 'Webinars',
          link: '#',
        },
        {
          title: 'Selling blocks',
          link: '#',
        },
        {
          title: 'Scenarios',
          link: '#',
        }
      ]
    },
    {
      svg: true,
      link: 'https://crm.smartinc.uk',
      img: (<svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25 9.4V20C25 22.7614 22.7614 25 20 25H6C3.23858 25 1 22.7614 1 20V6C1 3.23858 3.23858 1 6 1H17.8"
                  stroke="white" strokeLinecap="round"/>
            <circle opacity="1" cx="2.4" cy="2.4" r="2.4" transform="matrix(-1 0 0 1 26.1992 1)" stroke="#466A68"/>
            <path opacity="1" d="M7 16.2998L11.2 11.4998L14.8 14.4998L19 9.69981" stroke="#466A68" strokeLinecap="round"
                  strokeLinejoin="round"/>
          </svg>
      ),
      title: 'CRM'
    },
    {
      svg: true,
      link: '#',
      img: (<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.57914 19.9478H6.05276C3.2622 19.9478 1 17.6856 1 14.8951V6.05276C1 3.2622 3.2622 1 6.05276 1H18.6847C21.4752 1 23.7374 3.2622 23.7374 6.05276V9.84233"
                stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.05469 6.68457L11.3659 9.00034C12.0074 9.28001 12.7361 9.28111 13.3784 9.00338L18.6866 6.70808"
                  stroke="#466A68" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M16.1568 22.4742V24.3555C16.1522 24.6035 16.2935 24.8312 16.5177 24.9372C16.742 25.0432 17.0076 25.0078 17.1964 24.8469L19.9463 22.4742H22.7024C23.9708 22.4742 24.9991 21.4459 24.9991 20.1775V15.9285C24.9991 15.3194 24.7571 14.7352 24.3264 14.3045C23.8957 13.8738 23.3115 13.6318 22.7024 13.6318H14.6639C13.3955 13.6318 12.3672 14.6601 12.3672 15.9285V20.1775C12.3672 21.4459 13.3955 22.4742 14.6639 22.4742H16.1568Z"
                  stroke="#466A68" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
      ),
      title: 'Messages',
      submenu: [
        {
          title: 'Inbox',
          link: '#',
        },
        {
          title: 'Newsletters',
          link: '#',
        },
        {
          title: 'Settings',
          link: '#',
        }
      ]
    },
  ];
};

export default getMenuItems2;
