import React from 'react';
import App from 'App';
import paths, { rootPaths } from './paths';
import { Navigate, createBrowserRouter } from 'react-router-dom';

import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../layouts/ErrorLayout';
import Landing from 'components/pages/landing/Landing';
import Invoice from 'components/app/e-commerce/Invoice';
import Billing from 'components/app/e-commerce/billing/Billing';
import Checkout from 'components/app/e-commerce/checkout/Checkout';
import ShoppingCart from 'components/app/e-commerce/cart/ShoppingCart';
import CustomersDetails from 'components/app/e-commerce/customers-details/CustomersDetails';
import OrderDetails from 'components/app/e-commerce/orders/order-details/OrderDetails';
import Products from 'components/app/e-commerce/product/Products';
import ProductDetails from 'components/app/e-commerce/product/product-details/ProductDetails';
import AddProduct from 'components/app/e-commerce/product/add-product/AddProduct';
import Orders from 'components/app/e-commerce/orders/order-list/Orders';
import Customers from 'components/app/e-commerce/customers/Customers';
import Courses from 'components/app/e-learning/course/Courses';
import CourseDetails from 'components/app/e-learning/course/course-details';
import CreateCourse from 'components/app/e-learning/course/create-a-course';
import TrainerProfile from 'components/app/e-learning/trainer-profile';
import StudentOverview from 'components/app/e-learning/student-overview';
import Ecommerce from 'components/dashboards/e-commerce';
import Lms from 'components/dashboards/lms';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import Dashboard from 'components/dashboards/default';
import keycloakRealmRoles from "../helpers/keycloakRealmRoles";
import GoodsSales from "../components/app/e-commerce/GoodsSales";
import SalesVolume from "../components/app/e-commerce/SalesVolume";
import RevenueByMonth from "../components/app/e-commerce/RevenueByMonth";
import IndicatorsCumulativeTotalByMonths from "../components/app/e-commerce/IndicatorsCumulativeTotalByMonths";
import SummarizedSalesFigures from "../components/app/e-commerce/SummarizedSalesFigures";
import ProjectStructures from "../components/app/kinescope/ProjectStructures";
import Calendar from '../components/app/calendar/Calendar'
import Kanban from "../components/app/kanban/Kanban";
import CreateEvent from "../components/app/events/create-an-event/CreateEvent";
import EventDetail from "../components/app/events/event-detail/EventDetail";
import EventList from "../components/app/events/event-list/EventList";
import Notifications from "../components/app/social/notifications/Notifications";
import Feed from "../components/app/social/feed/Feed";
import ActivityLog from "../components/app/social/activity-log/ActivityLog";
import Followers from "../components/app/social/followers/Followers";
import Profile from "../components/pages/user/profile/Profile";
import Settings from "../components/pages/user/settings/Settings";

const routes = [
  {
    element: <App />,
    children: [
      {
        path: 'landing',
        element: <Landing />
      },
      {
        path: rootPaths.errorsRoot,
        element: <ErrorLayout />,
        children: [
          {
            path: paths.error404,
            element: <Error404 />
          },
          {
            path: paths.error500,
            element: <Error500 />
          }
        ]
      },
      {
        path: '/',
        element: <MainLayout />,
        children: [
          {
            index: true,
            element: <Dashboard />
          },
          {
            path: rootPaths.dashboardRoot,
            children: [
              {
                path: paths.ecommerce,
                element: <Ecommerce />
              },
              {
                path: paths.lms,
                element: <Lms />
              }
            ]
          },
          {
            path: rootPaths.appsRoot,
            children: [
              {
                path: paths.calendar,
                element: <Calendar />
              },
              {
                path: paths.kanban,
                element: <Kanban />
              }
            ]
          },
          {
            path: rootPaths.eventsRoot,
            children: [
              {
                path: paths.createEvent,
                element: <CreateEvent />
              },
              {
                path: paths.eventDetail,
                element: <EventDetail />
              },
              {
                path: paths.eventList,
                element: <EventList />
              }
            ]
          },
          {
            path: rootPaths.socialRoot,
            children: [
              {
                path: paths.feed,
                element: <Feed />
              },
              {
                path: paths.activityLog,
                element: <ActivityLog />
              },
              {
                path: paths.notifications,
                element: <Notifications />
              },
              {
                path: paths.followers,
                element: <Followers />
              }
            ]
          },
          {
            path: rootPaths.eCommerceRoot,
            children: [
              {
                path: paths.orderDetails,
                element: <OrderDetails />
              },
              {
                path: paths.orderList,
                element: <Orders />
              },
              {
                path: paths.customers,
                element: <Customers />
              },
              {
                path: paths.customerDetails,
                element: <CustomersDetails />
              },
              {
                path: paths.shoppingCart,
                element: <ShoppingCart />
              },
              {
                path: paths.checkout,
                element: <Checkout />
              },
              {
                path: paths.billing,
                element: <Billing />
              },
              {
                path: paths.invoice,
                element: <Invoice />
              },
              {
                path: paths.addProduct,
                element: <AddProduct />
              },
              {
                path: paths.products(':productLayout'),
                element: <Products />
              },
              {
                path: paths.productDetails(':productId'),
                element: <ProductDetails />
              },
              {
                path: paths.productDetails(),
                element: <ProductDetails />
              }
            ]
          },
          {
            path: rootPaths.eLearningRoot,
            children: [
              {
                path: paths.courses(':courseLayout'),
                element: <Courses />
              },
              {
                path: paths.courseDetails(),
                element: <CourseDetails />
              },
              {
                path: paths.createCourse,
                element: <CreateCourse />
              },
              {
                path: paths.courseDetails(':courseId'),
                element: <CourseDetails />
              },
              {
                path: paths.studentOverview,
                element: <StudentOverview />
              },
              {
                path: paths.trainerProfile,
                element: <TrainerProfile />
              }
            ]
          },
          {
            path: rootPaths.userRoot,
            children: [
              {
                path: paths.userProfile,
                element: <Profile />
              },
              {
                path: paths.userSettings,
                element: <Settings />
              }
            ]
          },
        ]
      },
      {
        path: '*',
        element: <Navigate to={paths.error404} replace />
      }
    ]
  }
];

export const router = createBrowserRouter(routes, {
  basename: process.env.PUBLIC_URL
});

export default routes;
