import
    './MenuElement.css';

import { NavLink } from 'react-router-dom';
import classNames from "classnames";

const MenuElement = ({element, onClickFn}) => {
    const { link, img, title, style, svg, active, submenu } = element;

    return (
        <li className="menuElementLi">
            <NavLink to={link}
                     className={classNames("menuElementA", {"linkActive":active})}
                     onClick={(evt)=>onClickFn(submenu, title, evt)}>
                {svg ? <>{img}</>:
                    <img alt={title} src={img} style={style} />
                }
                <div className="popupTitle">{title}</div>
            </NavLink>
        </li>
    );
};

export default MenuElement;

