import React from 'react';
import './SmartMenu.css';
import getMenuItems2 from './menuItems';
// import {AppContext} from "@edx/frontend-platform/react";
import MenuElement from './menu-elements/MenuElement';

const SmartMenu = () => {
    const menuItems = getMenuItems2();
    const bottomMenuItems = []

    const [subMenu, setSubMenu] = React.useState([]);
    const [subTitle, setSubTitle] = React.useState("");

    function onMenuClick(submenuitems, title, event) {
        console.log('i called');
        console.log(submenuitems);
        if(submenuitems) {
            event.preventDefault();
            if(title !== subTitle) {
                setSubMenu(submenuitems);
                setSubTitle(title);
            } else {
                setSubMenu([]);
                setSubTitle("");
            }
        }
    }

    // console.log({menuItems});

    function handleMouseLeave() {
        setSubMenu([]);
        setSubTitle("");
    }

    return (
        <>
            <div className="menu" onMouseLeave={handleMouseLeave}>
                <ul className="menuUl">
                    {
                        menuItems.length ? menuItems.map((menuItem, index) => (
                                <MenuElement element={menuItem} onClickFn={onMenuClick} key={index}/>
                            ))
                            : <span>No menu!</span>
                    }
                    <li className='spacer'></li>
                    {/* правила итд*/}
                    {bottomMenuItems.length && bottomMenuItems.map((menuItem, index) => (
                        <MenuElement element={menuItem} onClickFn={onMenuClick} key={index}/>
                    ))}
                </ul>
                {subMenu.length &&
                    <ul className="submenuUl">
                        <h3>{subTitle}</h3>
                        <hr/>
                        {subMenu.map((menuItem, index) => (
                            <li key={index}>
                                <a href={menuItem.link}>{menuItem.title}</a>
                            </li>
                        ))}
                    </ul>}
            </div>
        </>
    )
};

export default SmartMenu;
