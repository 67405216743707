import paths, { rootPaths } from './paths';

export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      children: [
        {
          name: 'Default',
          to: rootPaths.root,
          exact: true,
          active: true
        },
        {
          name: 'E Commerce',
          to: paths.ecommerce,
          active: true
        },
        {
          name: 'LMS',
          to: paths.lms,
          active: true,
          badge: {
            type: 'success',
            text: 'New'
          }
        },
      ]
    }
  ]
};
export const appRoutes = {
  label: 'app',
  children: [
    {
      name: 'Calendar',
      icon: 'calendar-alt',
      to: paths.calendar,
      active: true
    },
    {
      name: 'Events',
      icon: 'calendar-day',
      active: true,
      children: [
        {
          name: 'Create an event',
          to: paths.createEvent,
          active: true
        },
        {
          name: 'Event detail',
          to: paths.eventDetail,
          active: true
        },
        {
          name: 'Event list',
          to: paths.eventList,
          active: true
        }
      ]
    },
    {
      name: 'E Commerce',
      icon: 'shopping-cart',
      active: true,
      children: [
        {
          name: 'Product',
          active: true,
          children: [
            {
              name: 'Add a product',
              to: paths.addProduct,
              active: true
            },
            {
              name: 'Product list',
              to: paths.products('product-list'),
              active: true
            },
            {
              name: 'Product grid',
              to: paths.products('product-grid'),
              active: true
            },
            {
              name: 'Product details',
              to: paths.productDetails(),
              active: true
            }
          ]
        },
        {
          name: 'Orders',
          active: true,
          children: [
            {
              name: 'Order list',
              to: paths.orderList,
              active: true
            },
            {
              name: 'Order details',
              to: paths.orderDetails,
              active: true
            }
          ]
        },
        {
          name: 'Customers',
          to: paths.customers,
          active: true
        },
        {
          name: 'Customer details',
          to: paths.customerDetails,
          active: true
        },
        {
          name: 'Shopping cart',
          to: paths.shoppingCart,
          active: true
        },
        {
          name: 'Checkout',
          to: paths.checkout,
          active: true
        },
        {
          name: 'Billing',
          to: paths.billing,
          active: true
        },
        {
          name: 'Invoice',
          to: paths.invoice,
          active: true
        }
      ]
    },
    {
      name: 'E Learning',
      icon: 'graduation-cap',
      active: true,
      badge: {
        type: 'success',
        text: 'New'
      },
      children: [
        {
          name: 'Course',
          active: true,
          children: [
            {
              name: 'Create a course',
              to: paths.createCourse,
              active: true
            },
            {
              name: 'Course list',
              to: paths.courses('course-list'),
              active: true
            },
            {
              name: 'Course grid',
              to: paths.courses('course-grid'),
              active: true
            },
            {
              name: 'Course details',
              to: paths.courseDetails(),
              active: true
            }
          ]
        },
        {
          name: 'Student overview',
          to: paths.studentOverview,
          active: true
        },
        {
          name: 'Trainer profile',
          to: paths.trainerProfile,
          active: true
        }
      ]
    }
  ]
};

export const pagesRoutes = {
  label: 'pages',
  children: []
};

export const modulesRoutes = {
  label: 'Modules',
  children: []
};

export const documentationRoutes = {
  label: 'documentation',
  children: []
};

export default [
  dashboardRoutes,
  appRoutes
];
